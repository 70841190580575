import { Box, Container, Link, Stack, Typography, alpha } from "@mui/material";
import { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import { AdsComponent } from "../AdsComponent/AdsComponent";

export default function ContactComponent() {
    return(
        <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 3, sm: 8 },
          borderBottom: '2px solid',
          borderColor: 'divider'
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(3.5rem, 10vw, 4rem)',
            }}
          >
            Contact&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: 'clamp(3rem, 10vw, 4rem)',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
              Information
            </Typography>
          </Typography>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' }, fontSize: '16px' }}
          >
            We value your feedback and are here to assist you with any questions, suggestions, or concerns you may have.
             Please feel free to reach out to us through our primary communication channels on Telegram.
          </Typography>
        </Stack>
      </Container>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 3, sm: 5 },
          pb: { xs: 8, sm: 12 },
          borderBottom: '2px solid',
          borderColor: 'divider'
        }}
      >
        <Stack spacing={2} useFlexGap>
        <Typography
            textAlign="start"
            sx={{ alignSelf: 'start',fontSize: '20px' }}
        >
            <b>Join Our Telegram Channel</b>
        </Typography>
          <Typography
            textAlign="start"
            color="text.secondary"
            sx={{ alignSelf: 'start', fontSize: '17px' }}
        >
            Stay updated with the latest news, updates, and releases by joining our Telegram channel:
            <p>
                <Link href="https://t.me/Channel_MGC_BSG">Our Telegram Channel {">>"}</Link>
            </p>
        </Typography>
        <Typography
            textAlign="start"
            sx={{ alignSelf: 'start', fontSize: '20px' }}
        >
            <b>Join Our Telegram Group</b>
        </Typography>
          <Typography
            textAlign="start"
            color="text.secondary"
            sx={{ alignSelf: 'start', fontSize: '17px' }}
        >
            Engage with other photography enthusiasts, get support, and participate in discussions by joining our Telegram group:
            <p>
                <Link href="https://t.me/PublicGroup_MGC_BSG">Our Telegram Group {">>"}</Link>
            </p>
        </Typography>
        <Typography
            textAlign="start"
            sx={{ alignSelf: 'start', fontSize: '20px' }}
        >
            <b>Business Inquiries</b>
        </Typography>
          <Typography
            textAlign="start"
            color="text.secondary"
            sx={{ alignSelf: 'start', fontSize: '17px' }}
        >
            For business inquiries, partnerships, or collaboration opportunities, please contact us at:           <p></p>
            <p>
              bsgcooperation@gmail.com
            </p>
        </Typography>
        <Typography
            textAlign="start"
            sx={{ alignSelf: 'start', fontSize: '20px' }}
        >
            <b>Feedback Form</b>
        </Typography>
          <Typography
            textAlign="start"
            color="text.secondary"
            sx={{ alignSelf: 'start', fontSize: '17px' }}
        >
             Alternatively, you can fill out our feedback form, and we will get back to you as soon as possible:
             <p>
                <Link href="https://forms.gle/Z5ByuVnTmbvfhP768">Our Feedback Form {">>"}</Link>
            </p>
        </Typography>
        </Stack>
        <Container sx={{alignItems: "center"}}>
      <AdsComponent 
          className='adsbygoogle'
          client={'ca-pub-5244484613216801'}
          slot={'8291271210"'}
          layoutKey="-6t+ed+2i-1n-4w"
          format='fluid'
          style={{display: "block", textAlign: "center"}}/>
    </Container>
      </Container>
      <Footer></Footer>
    </Box>
    );
}