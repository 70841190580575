import { Container, Stack, Typography } from "@mui/material";
import ParametersTable from "./ParametersTable";
import { useNavigate } from "react-router-dom";
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { AdsComponent } from "../AdsComponent/AdsComponent";


export default function AdvancedSettings() {
    const navigate = useNavigate();

    const rows = [
        { name: 'ux_mode_override', description: 'User mode setting to activate specific functions in the interface.' },
        { name: 'evc_override', description: 'Changing exposure parameters to control lighting based on shooting conditions.' },
        { name: 'clean_aeb', description: 'Automatic exposure bracketing for greater dynamic range.' },
        { name: 'clean_ae', description: 'Exposure selection based on scene conditions to improve overall brightness.' },
        { name: 'clean_fps', description: 'Adjusting frames per second for video recording, enhancing motion smoothness.' },
        { name: 'clean_hdr', description: 'Enabling HDR mode to enhance detail in highlights and shadows.' },
        { name: 'clean_portrait', description: 'Adjusting portrait shooting settings for background blur effects.' },
        { name: 'clean_night', description: 'Optimizing settings for low-light photography.' },
        { name: 'clean_macro', description: 'Settings for macro photography to capture details of close objects.' },
        { name: 'clean_video_stabilization', description: 'Reducing shake in video for smoother footage.' },
        { name: 'clean_iso', description: 'ISO adjustment to increase light sensitivity, especially in dark conditions.' },
        { name: 'clean_metering', description: 'Selecting a light metering method to achieve proper exposure.' },
        { name: 'clean_denoise', description: 'Reducing noise in images, especially at high ISO values.' },
        { name: 'clean_lens_shading', description: 'Correcting color distortions at the edges of the image.' },
        { name: 'clean_bokeh', description: 'Settings for creating beautiful background blur, particularly useful in portrait photography.' }
    ];
    

    return(
        <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          pt: { xs: 4, sm: 5 },
          pb: { xs: 3, sm: 8 },
          borderBottom: '2px solid',
          borderColor: 'divider'
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: 'text.primary', textAlign: 'left' }}
        >
          <b>Advanced Settings</b>
        </Typography>
          <Typography
            textAlign="left"
            color="text.secondary"
            sx={{ alignSelf: 'left', fontSize: '16px' }}
          >
            These parameters are designed for more experienced users who want to control every detail of their photos:
          </Typography>
          <ParametersTable rows={rows}/>
          <Typography 
            textAlign="start" 
            sx={{ alignSelf: 'start', fontSize: '17px'}} 
            style={{ cursor: 'pointer' }} 
            onClick={() => navigate('/parameters-dictionary-bsg')}
        >
            <SubdirectoryArrowRightIcon fontSize='small'/> <b> See full Parameters Dictionary</b>
        </Typography>
        </Stack>
        <Container sx={{alignItems: "center"}}>
      <AdsComponent 
          className='adsbygoogle'
          client={'ca-pub-5244484613216801'}
          slot={'8291271210"'}
          layoutKey="-6t+ed+2i-1n-4w"
          format='fluid'
          style={{display: "block", textAlign: "center"}}/>
    </Container>
        </Container>
    );
}