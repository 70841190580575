import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import { AdsComponent } from '../AdsComponent/AdsComponent';

const items = [
  {
    icon: <SettingsSuggestRoundedIcon />,
    title: 'Optimized Versions for Your Device',
    description:
      'We offer a wide selection of GCam versions specifically optimized for various Android devices. Each APK is tailored to ensure compatibility and performance, so you get the best possible results with your smartphone’s camera.',
  },
  {
    icon: <ConstructionRoundedIcon />,
    title: 'Regular Updates and Latest Features',
    description:
      'We continuously update our library with the latest GCam versions and features, ensuring you have access to cutting-edge photography technology. Our commitment to keeping our content current means you won’t miss out on new enhancements.',
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: 'Verified and Safe Downloads',
    description:
      'All our APKs are thoroughly tested and verified for safety, ensuring that you can download and install them with confidence. Your security is our top priority.',
  },
  {
    icon: <AutoFixHighRoundedIcon />,
    title: 'User-Friendly Installation Guides',
    description:
      'We provide clear and detailed installation instructions for GCam versions, making it easy for both beginners and experienced users to install and set up the app without any hassle.',
  },
  {
    icon: <SupportAgentRoundedIcon />,
    title: 'Community Feedback and Ratings',
    description:
    'Our large and active Telegram community shares detailed reviews, feedback, and ratings for each GCam version. This real-time feedback helps you stay informed about the latest features, compatibility issues, and performance improvements directly from fellow users.'
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: 'Wide Device Compatibility',
    description:
      'We offer GCam APKs that are tailored and optimized for a wide range of Android devices. Our collection includes custom configurations that make GCam accessible and functional on various smartphones, even those not officially supported.',
  },
];

export default function WhyDownloadPart() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: 'grey.900',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4" gutterBottom>
          Why Download from Us?
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
          At gcam-mode, we strive to provide the best experience for Android users looking to enhance their photography skills with Google Camera (GCam). Here’s why you should choose us for your GCam APK downloads
          </Typography>
        </Box>
        <Grid container spacing={4}>
          {items.map((item, index) => (
            <Grid item  xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  color: 'inherit',
                  p: 3,
                  height: '100%',
                  borderColor: 'hsla(220, 25%, 25%, 0.3)',
                  backgroundColor: 'grey.800',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography gutterBottom sx={{ fontWeight: 'medium' }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}