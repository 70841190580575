import { Box, Container, Stack, Typography, alpha } from "@mui/material";
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import { AdsComponent } from "../AdsComponent/AdsComponent";

export default function AboutComponent() {
    const navigate = useNavigate();
    return(
        <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 3, sm: 8 },
          borderBottom: '2px solid',
          borderColor: 'divider'
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(3.5rem, 10vw, 4rem)',
            }}
          >
            About BSG&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: 'clamp(3rem, 10vw, 4rem)',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
              GCam
            </Typography>
          </Typography>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' }, fontSize: '16px' }}
          >
            Discover the magic of mobile photography with BSG’s GCam ports. We are dedicated to bringing you the best Google Camera enhancements, 
            meticulously crafted by the renowned developer BSG. Dive into our world and unlock the true potential of your smartphone camera.
          </Typography>
        </Stack>
      </Container>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 3, sm: 5 },
          pb: { xs: 8, sm: 12 },
          borderBottom: '2px solid',
          borderColor: 'divider'
        }}
      >

        <Container sx={{
          alignItems: "center",
          pb: { xs: 3, sm: 5 },
          }}>
            <AdsComponent 
                className='adsbygoogle'
                client={'ca-pub-5244484613216801'}
                slot={'8659270011'}
                format='fluid'
                data-full-width-responsive="true"
                style={{display: "block", textAlign: "center"}}/>
    </Container>
    
        <Stack spacing={2} useFlexGap>
        <Typography
            textAlign="start"
            sx={{ alignSelf: 'start',fontSize: '20px' }}
        >
            <b>Welcome to BSG Gcam</b>
        </Typography>
          <Typography
            textAlign="start"
            color="text.secondary"
            sx={{ alignSelf: 'start', fontSize: '17px' }}
        >
            Here we are passionate about enhancing your mobile photography experience through the power of Google Camera 
            (GCam) ports. Our mission is to provide you with the best GCam ports created by the renowned developer BSG, ensuring that you 
            can capture stunning photos with ease.
        </Typography>
        <Typography
            textAlign="start"
            sx={{ alignSelf: 'start', fontSize: '20px' }}
        >
            <b>Who is BSG?</b>
        </Typography>
          <Typography
            textAlign="start"
            color="text.secondary"
            sx={{ alignSelf: 'start', fontSize: '17px' }}
        >
            BSG is a developer known for his exceptional work in porting Google Camera to various Android devices.
             With a deep understanding of mobile photography and a commitment to quality, BSG's ports are renowned for their 
             performance and reliability. His efforts have helped countless users unlock the full potential of their smartphone cameras.
        </Typography>
        <Typography
            textAlign="start"
            sx={{ alignSelf: 'start', fontSize: '20px' }}
        >
            <b>What We Offer</b>
        </Typography>
          <Typography
            textAlign="start"
            color="text.secondary"
            sx={{ alignSelf: 'start', fontSize: '17px' }}
        >
            At BSG Gcam, we provide a comprehensive collection of BSG’s GCam ports, tailored to enhance your mobile photography experience. Our site features:
           <p></p>
            <p>
                <b>Latest GCam Ports:</b> Access the newest and most stable versions of BSG’s GCam ports.
            </p>
            <p>
                <b>Compatibility Information:</b> Detailed guides on which GCam ports work best with specific devices.
            </p>
            <p>
                <b>Support and Community:</b> Join our community of photography enthusiasts and get support for any issues you encounter.
            </p>
        </Typography>
        <Typography
            textAlign="start"
            sx={{ alignSelf: 'start', fontSize: '20px' }}
        >
            <b>Our Commitment</b>
        </Typography>
          <Typography
            textAlign="start"
            color="text.secondary"
            sx={{ alignSelf: 'start', fontSize: '17px' }}
        >
             We are committed to delivering high-quality content and ensuring that our users have the best possible experience with GCam.
              We strive to keep our site updated with the latest ports and information, and we are always here to assist you with any 
              questions or concerns.
        </Typography>
        <Typography
            textAlign="start"
            sx={{ alignSelf: 'start', fontSize: '20px' }}
        >
            <b>Join Us</b>
        </Typography>
          <Typography
            textAlign="start"
            color="text.secondary"
            sx={{ alignSelf: 'start', fontSize: '17px'}}
        >
            Join us on our journey to transform mobile photography. Explore the world of Google Camera with BSG’s 
            innovative ports and take your photos to the next level.
        </Typography>
        <Typography 
            textAlign="start" 
            sx={{ alignSelf: 'start', fontSize: '17px'}} 
            style={{ cursor: 'pointer' }} 
            onClick={() => navigate('/versions-bsg')}
        >
            <SubdirectoryArrowRightIcon fontSize='small'/> <b> Explore all BSG versions</b>
        </Typography>
        </Stack>
        <Container sx={{
          alignItems: "center",
          pt: { xs: 3, sm: 5 }
          }}>
            <AdsComponent 
                className='adsbygoogle'
                client={'ca-pub-5244484613216801'}
                slot={'8659270011'}
                format='fluid'
                data-full-width-responsive="true"
                style={{display: "block", textAlign: "center"}}/>
    </Container>
      </Container>
      <Footer></Footer>
    </Box>
    );
}