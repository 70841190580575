import { alpha, Box, Container, Stack, Typography } from "@mui/material";
import CommonPage from "../Common/CommonPage";
import Footer from "../Footer/Footer";
import SetupsIntroduction from "./SetupsIntroduction";
import SetupsMain from "./SetupsMain";
import { AdsComponent } from "../AdsComponent/AdsComponent";


export default function Setups() {
    let main;

    main = (
        <Box
        id="hero"
        sx={(theme) => ({
            width: '100%',
            backgroundImage:
            theme.palette.mode === 'light'
                ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
                : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
            backgroundSize: '100% 20%',
            backgroundRepeat: 'no-repeat',
        })}
        >
            <SetupsIntroduction/>
            <Container sx={{alignItems: "center"}}>
      <AdsComponent 
          className='adsbygoogle'
          client={'ca-pub-5244484613216801'}
          slot={'8291271210"'}
          layoutKey="-6t+ed+2i-1n-4w"
          format='fluid'
          style={{display: "block", textAlign: "center"}}/>
    </Container>
            <SetupsMain/>
            <Container sx={{alignItems: "center"}}>
      <AdsComponent 
          className='adsbygoogle'
          client={'ca-pub-5244484613216801'}
          slot={'8291271210"'}
          layoutKey="-6t+ed+2i-1n-4w"
          format='fluid'
          style={{display: "block", textAlign: "center"}}/>
    </Container>
            <Footer/>
        </Box>
    );
    return(
        <CommonPage main={main}/>
    );
}