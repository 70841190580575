import { alpha, Box, Container, Stack, Typography } from "@mui/material";
import CommonPage from "../Common/CommonPage";
import ParametersIntroduction from "./ParametersIntroduction";
import BasicProcessingParameters from "./BasicProcessingParameters";
import AdvancedSettings from "./AdvancedSettings";
import Footer from "../Footer/Footer";
import { AdsComponent } from "../AdsComponent/AdsComponent";


export default function ParametersGuide() {
    let main;

    main = (
        <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
        <ParametersIntroduction/>

        <Container sx={{alignItems: "center"}}>
      <AdsComponent 
          className='adsbygoogle'
          client={'ca-pub-5244484613216801'}
          slot={'8291271210"'}
          layoutKey="-6t+ed+2i-1n-4w"
          format='fluid'
          style={{display: "block", textAlign: "center"}}/>
    </Container>
        <BasicProcessingParameters/>

        <Container sx={{alignItems: "center"}}>
      <AdsComponent 
          className='adsbygoogle'
          client={'ca-pub-5244484613216801'}
          slot={'8291271210"'}
          layoutKey="-6t+ed+2i-1n-4w"
          format='fluid'
          style={{display: "block", textAlign: "center"}}/>
    </Container>
        <AdvancedSettings/>
        <Footer/>
    </Box>
    );
    return(
        <CommonPage main={main}/>
    );
}