import { Box, Container, Link, Stack, Typography } from "@mui/material";
import { AdsComponent } from "../AdsComponent/AdsComponent";

export default function InstallationGuide() {
    return(
        <Box>
            <Container
            sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            pt: { xs: 14, sm: 20 },
            pb: { xs: 3, sm: 8 },
            borderBottom: '2px solid',
            borderColor: 'divider'
            }}
        >
            <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '100%' } }}>
            <Typography
                variant="h1"
                sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                alignSelf: 'center',
                textAlign: 'center',
                fontSize: 'clamp(3.5rem, 10vw, 4rem)',
                }}
            >
                Installation&nbsp;
                <Typography
                component="span"
                variant="h1"
                sx={{
                    fontSize: 'clamp(3rem, 10vw, 4rem)',
                    color: (theme) =>
                    theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                }}
                >
                Guide
                </Typography>
            </Typography>
            <Typography
            variant="h4"
                textAlign="left"
                color="text.secondary"
                sx={{ alignSelf: 'left', fontSize: '16px' }}
            >
                <b>for GCAm</b>
            </Typography>
            <Typography
                textAlign="left"
                color="text.secondary"
                sx={{ alignSelf: 'left',  fontSize: '16px', width: { xs: '100%', sm: '80%' }}}
            >
                This step-by-step guide will walk you through the GCam installation process. Please follow each step carefully to ensure a smooth installation.
            </Typography>
            </Stack>
            </Container>
            <Container sx={{alignItems: "center"}}>
      <AdsComponent 
          className='adsbygoogle'
          client={'ca-pub-5244484613216801'}
          slot={'8291271210"'}
          layoutKey="-6t+ed+2i-1n-4w"
          format='fluid'
          style={{display: "block", textAlign: "center"}}/>
    </Container>
            <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                pt: { xs: 7, sm: 10 },
                pb: { xs: 3, sm: 8 },
                borderBottom: '2px solid',
                borderColor: 'divider'
                }}>
                <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '100%' } }}>
                <Box>
            {/* Step 1 */}
            <Typography
                component="h2"
                variant="h4"
                gutterBottom
                sx={{ color: 'text.primary', textAlign: 'left' }}
            >
                <strong>Step 1: Enable Installation from Unknown Sources</strong><br />
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: 'text.secondary', textAlign: 'left' }}>
                To install GCam, you may need to allow installations from unknown sources on your device:
            </Typography>
            <ol style={{ color: 'text.secondary', textAlign: 'left' }}>
                <li>Open <strong>Settings</strong> on your Android device.</li>
                <li>Scroll down and tap on <strong>Security</strong> or <strong>Privacy</strong> (depending on your device).</li>
                <li>Look for <strong>Install Unknown Apps</strong> or <strong>Unknown Sources</strong>.</li>
                <li>Select your browser (e.g., Chrome) and toggle <strong>Allow from this source</strong>.</li>
            </ol>
            <Typography variant="body2" gutterBottom sx={{ color: 'text.secondary', fontStyle: 'italic' }}>
                Note: This is necessary since GCam APKs are not from the Play Store.
            </Typography>

            {/* Step 2 */}
            <Typography
                component="h2"
                variant="h4"
                gutterBottom
                sx={{ color: 'text.primary', textAlign: 'left', marginTop: '2rem' }}
            >
                <strong>Step 2: Download the GCam APK</strong><br />
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: 'text.secondary', textAlign: 'left' }}>
                Visit our Versions section to find the correct APK for your device model:
            </Typography>
            <ol style={{ color: 'text.secondary', textAlign: 'left' }}>
                <li>Go to the <strong>Versions</strong> section on our site.</li>
                <li>Find the proper version and download the appropriate APK.</li>
                <li>Once downloaded, locate the file in your <strong>Downloads</strong> folder.</li>
            </ol>
            <Typography variant="body2" gutterBottom sx={{ color: 'text.secondary', fontStyle: 'italic' }}>
                Tip: Using Wi-Fi is recommended for large downloads to avoid mobile data charges.
            </Typography>

            {/* Step 3 */}
            <Typography
                component="h2"
                variant="h4"
                gutterBottom
                sx={{ color: 'text.primary', textAlign: 'left', marginTop: '2rem' }}
            >
                <strong>Step 3: Install the GCam APK</strong><br />
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: 'text.secondary', textAlign: 'left' }}>
                After downloading, follow these steps to install GCam on your device:
            </Typography>
            <ol style={{ color: 'text.secondary', textAlign: 'left' }}>
                <li>Open <strong>File Manager</strong> or your <strong>Downloads</strong> app.</li>
                <li>Locate and tap the GCam APK to start the installation.</li>
                <li>Tap <strong>Install</strong> when prompted.</li>
                <li>Once installed, tap <strong>Open</strong> to launch GCam.</li>
            </ol>
            <Typography variant="body2" gutterBottom sx={{ color: 'text.secondary', fontStyle: 'italic' }}>
                Note: If you encounter an "Installation Blocked" error, re-check the security settings.
            </Typography>

            {/* Step 4 */}
            <Typography
                component="h2"
                variant="h4"
                gutterBottom
                sx={{ color: 'text.primary', textAlign: 'left', marginTop: '2rem' }}
            >
                <strong>Step 4: Initial Setup and Configuration</strong><br />
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: 'text.secondary', textAlign: 'left' }}>
                Once GCam is installed, follow these optional setup steps to get the most out of the app:
            </Typography>
            <ol style={{ color: 'text.secondary', textAlign: 'left' }}>
                <li><strong>Grant Permissions:</strong> Allow GCam to access your camera, storage, and location when prompted.</li>
                <li><strong>Explore Settings:</strong> Customize settings for HDR+, Night Sight, and other modes based on your preferences.</li>
                <li><strong>Test Modes:</strong> Experiment with different modes (e.g., Portrait, Night Sight) to ensure optimal performance on your device.</li>
            </ol>
            <Typography variant="body2" gutterBottom sx={{ color: 'text.secondary', fontStyle: 'italic' }}>
                Tip: Adjust settings like HDR and exposure levels to enhance image quality.
            </Typography>
        </Box>
                </Stack>
            </Container>

            <Container sx={{alignItems: "center"}}>
      <AdsComponent 
          className='adsbygoogle'
          client={'ca-pub-5244484613216801'}
          slot={'8291271210"'}
          layoutKey="-6t+ed+2i-1n-4w"
          format='fluid'
          style={{display: "block", textAlign: "center"}}/>
    </Container>
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                    pt: { xs: 7, sm: 10 },
                    pb: { xs: 3, sm: 8 },
                    borderBottom: '2px solid',
                    borderColor: 'divider'
                    }}
            >
                <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '100%' } }}>
                    <Box sx={{ textAlign: 'left' }}> 
                        {/* Troubleshooting Section */}
                        <Typography component="h2" variant="h4" gutterBottom sx={{ color: 'text.primary', textAlign: 'left' }}>
                            <strong>Troubleshooting Common Installation Issues</strong>
                        </Typography>

                        {/* Issue 1 */}
                        <Typography component="h3" variant="h6" gutterBottom sx={{ color: 'text.secondary', textAlign: 'left' }}>
                            Issue: Installation is blocked despite enabling "Unknown Sources"
                        </Typography>
                        <Typography variant="body1" gutterBottom sx={{ textAlign: 'left' }}>
                            <strong>Solution:</strong> Double-check that you’ve enabled installations from unknown sources specifically for the app you used to download the APK (e.g., Chrome). Additionally:
                        </Typography>
                        <ul>
                            <li>Ensure the setting is enabled under your browser's settings in <strong>Install Unknown Apps</strong>.</li>
                            <li>Restart your device and try the installation again.</li>
                        </ul>

                        {/* Issue 2 */}
                        <Typography component="h3" variant="h6" gutterBottom sx={{ color: 'text.secondary', textAlign: 'left' }}>
                            Issue: “App Not Installed” error appears after attempting installation
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <strong>Solution:</strong> This error often occurs if the APK is incompatible with your device. Make sure you’ve downloaded the version of GCam specifically designed for your device’s model and chipset.
                        </Typography>

                        {/* Issue 3 */}
                        <Typography component="h3" variant="h6" gutterBottom sx={{ color: 'text.secondary', textAlign: 'left' }}>
                            Issue: GCam crashes or doesn’t open after installation
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <strong>Solution:</strong> Incompatibility with certain devices can cause this issue. Here are some solutions:
                        </Typography>
                        <ul>
                            <li>Try an alternative version of GCam that’s known to work on similar devices.</li>
                            <li>Clear the app cache: Go to <strong>Settings {">"} Apps {">"} GCam {">"} Storage {">"} Clear Cache</strong>, then reopen the app.</li>
                        </ul>

                        {/* Advanced Tips Section */}
                        <Typography component="h2" variant="h4" gutterBottom sx={{ color: 'text.primary', textAlign: 'left', marginTop: '2rem' }}>
                            <strong>Advanced Tips for a Better GCam Experience</strong>
                        </Typography>

                        {/* Tip 2 */}
                        <Typography component="h3" variant="h6" gutterBottom sx={{ color: 'text.secondary', textAlign: 'left' }}>
                            Enable Experimental Features
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Some GCam versions include experimental features under <strong>Settings {">"} Advanced</strong>. Experienced users may explore these options for extra customization, including:
                        </Typography>
                        <ul>
                            <li><strong>Astrophotography Mode:</strong> Capture detailed night skies.</li>
                            <li><strong>Custom Denoising:</strong> Adjust denoising levels for low-light and high-ISO conditions.</li>
                            <li><strong>RAW Image Capture:</strong> Save images in RAW format for advanced post-processing.</li>
                        </ul>

                        {/* Tip 3 */}
                        <Typography component="h3" variant="h6" gutterBottom sx={{ color: 'text.secondary', textAlign: 'left' }}>
                            <Link href="https://t.me/Channel_MGC_BSG">Join the Community for Support</Link>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            For updates, tips, and support, consider joining a GCam community. Communities often share compatible versions, troubleshoot issues, and provide tips for getting the best results.
                        </Typography>

                    </Box>
                    <Container sx={{alignItems: "center"}}>
      <AdsComponent 
          className='adsbygoogle'
          client={'ca-pub-5244484613216801'}
          slot={'8291271210"'}
          layoutKey="-6t+ed+2i-1n-4w"
          format='fluid'
          style={{display: "block", textAlign: "center"}}/>
    </Container>
                </Stack>
            </Container>
        </Box>
    );
}