import { Box, Container, Link, Stack, Typography } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import { useEffect, useState } from "react";
import ParametersTable from "../Parameters Guide/ParametersTable";
import { AdsComponent } from "../AdsComponent/AdsComponent";

const main = [
    {
      "name": "Sharpness A",
      "description": "Primary sharpness control to adjust overall image clarity. Default value of 1 ensures a balanced level of detail without introducing excessive noise."
    },
    {
      "name": "Sharpness B",
      "description": "Secondary sharpness control focusing on finer details. Default value of 1 maintains clarity without over-enhancing edges."
    },
    {
      "name": "Chroma A",
      "description": "Adjusts the reduction of color noise (chroma noise) in the image. Default value of 1 balances noise suppression and color fidelity."
    },
    {
      "name": "Chroma B",
      "description": "Targets finer color noise reduction for smaller textures. Default value of 1 preserves subtle details while reducing chromatic artifacts."
    },
    {
      "name": "Luma A",
      "description": "Controls adjustments to luminance (brightness) noise. Default value of 1 ensures a smooth tone while retaining image details."
    },
    {
      "name": "Luma B",
      "description": "Fine-tunes luminance noise handling. Default value of 1 minimizes grain without over-smoothing textures."
    },
    {
      "name": "Luma Denoise A",
      "description": "Manages overall luminance noise reduction. Default value of 1 suppresses noise while preserving key details."
    },
    {
      "name": "Luma Denoise B",
      "description": "Focuses on reducing fine luminance noise. Default value of -1 prioritizes retaining textures and natural appearance."
    },
    {
      "name": "Luma noise",
      "description": "Balances the presence of luminance noise in the image. Default value of 1 minimizes grain while avoiding unnatural blurring."
    },
    {
      "name": "LUT Noise Fix",
      "description": "Reduces noise artifacts caused by LUT (Look-Up Table) processing. Default value of 4.0 ensures smoother color transitions and stable brightness levels."
    },
    {
      "name": "Temporal A",
      "description": "Controls temporal noise reduction strength to improve image clarity in multi-frame captures. Default value of 1 offers balanced denoising."
    },
    {
      "name": "Temporal B",
      "description": "Fine-tunes temporal noise reduction for small details in dynamic scenes. Default value of 1 enhances consistency across frames."
    },
    {
      "name": "Spatial A",
      "description": "Manages spatial noise reduction to handle pixel-level inconsistencies. Default value of 1 reduces noise without softening edges excessively."
    },
    {
      "name": "Spatial B",
      "description": "Adjusts finer spatial noise reduction for subtle improvements. Default value of 1 enhances smoothness without affecting sharpness."
    },
    {
      "name": "MAX Overall Gain (ISO Limit)",
      "description": "Sets the maximum ISO sensitivity for the camera. Default value of 102 (ISO 10200) limits brightness boost to prevent excessive noise in low light."
    },
    {
      "name": "NS Gain Multiplier (P5 Models and Older)",
      "description": "Determines noise suppression intensity for older models. Default value of 2.5 balances noise reduction and detail preservation."
    },
    {
      "name": "NS Gain Multiplier (P6 Models and Newer)",
      "description": "Adjusts noise suppression for newer models. Default value of 2.5 ensures cleaner images while maintaining texture quality."
    },
    {
        "name": "Fix RAW16 Merge",
        "description": "Improves the merging process for RAW16 files, ensuring better image consistency and dynamic range."
      },
      {
        "name": "Highlight Fix (ZSL)",
        "description": "Corrects overexposed highlights when using Zero Shutter Lag mode, enhancing bright areas of the image."
      },
      {
        "name": "Highlight Fix (HDRE)",
        "description": "Addresses overexposure issues in highlights during HDR Enhanced processing, preserving detail in bright areas."
      },
      {
        "name": "Highlight Fix 2",
        "description": "Additional adjustments to fix highlight clipping in challenging lighting scenarios."
      },
      {
        "name": "Skip Metadata Check",
        "description": "Allows the application to bypass metadata validation, enabling compatibility with non-standard or custom files."
      },
      {
        "name": "Allow Unknown Devices",
        "description": "Enables support for devices that are not officially recognized or validated by the application."
      },
      {
        "name": "Allow Spacial RGB",
        "description": "Activates spatial RGB processing for enhanced color and detail accuracy in images."
      },
      {
        "name": "Sabre Motion Fix",
        "description": "Addresses motion artifacts in Sabre processing, resulting in smoother motion and reduced ghosting."
      },
      {
        "name": "Max Frame Count (HDRE)",
        "description": "Sets the maximum number of frames used for HDR Enhanced processing to ensure optimal dynamic range."
      },
      {
        "name": "Min Bracketing Frames",
        "description": "Defines the minimum number of frames required for bracketing, ensuring proper exposure blending."
      },
      {
        "name": "Max Bracketing Frames",
        "description": "Limits the maximum number of frames used for bracketing to balance performance and quality."
      },
      {
        "name": "Min Short Frames",
        "description": "Specifies the minimum number of short exposure frames for bracketing, aiding in highlight preservation."
      },
      {
        "name": "Max Short Frames",
        "description": "Sets the maximum number of short exposure frames for bracketing, controlling highlight detail."
      },
      {
        "name": "Shasta Force",
        "description": "Forces specific processing parameters in Shasta mode for improved image quality in low-light scenarios."
      },
      {
        "name": "Shasta Factor",
        "description": "Controls the intensity of Shasta processing adjustments, enhancing low-light and high-dynamic-range shots."
      },
      {
        "name": "Shasta ZSL Set",
        "description": "Optimizes Shasta settings for use in Zero Shutter Lag mode, improving low-light performance."
      },
      {
        "name": "ISO MAX ZSL Analog",
        "description": "Defines the maximum ISO sensitivity allowed for analog processing in Zero Shutter Lag mode."
      },
      {
        "name": "MAX Relative TET Factor",
        "description": "Specifies the maximum relative Time Exposure Threshold factor. Default value of 2 ensures balanced exposure across frames."
      },
      {
        "name": "Max Bracketed TET Ratio",
        "description": "Limits the ratio between longest and shortest exposure times in bracketing, ensuring consistent exposure blending."
      },
      {
        "name": "HARD JPG Quality",
        "description": "Sets the compression quality for JPG output files. Default value of 95 ensures high-quality image output."
      },
      {
        "name": "Blur Preview",
        "description": "Applies a blur effect to the preview image to simulate depth-of-field or artistic effects."
      },
      {
        "name": "Noise Model Unblock",
        "description": "Enables advanced noise model adjustments to enhance image clarity, especially in low-light conditions."
      },
      {
        "name": "Compress Merged DNG",
        "description": "Applies compression to merged DNG files, reducing file size while maintaining image quality."
      },
      {
        "name": "Resample Method",
        "description": "Defines the algorithm used for resampling images, impacting image scaling quality."
      },
      {
        "name": "User Experience Mode",
        "description": "Adjusts settings to prioritize user experience, balancing performance, usability, and image quality."
      },
      {
        "name": "Optimize Sky",
        "description": "Improves rendering and color balance for skies in images, especially in HDR scenarios."
      },
      {
        "name": "Disable Safe UnderExposure",
        "description": "Disables the default safeguard against underexposure, allowing for darker images in specific cases."
      },
      {
        "name": "Apply Portrait Matting",
        "description": "Applies advanced portrait matting techniques for improved subject-background separation in portrait mode."
      },
      {
        "name": "Portrait Use Opencl Depth",
        "description": "Utilizes OpenCL acceleration to improve depth map generation for portrait mode, enhancing subject isolation."
      },
      {
        "name": "Portrait Relighting Option",
        "description": "Enables advanced relighting adjustments in portrait mode to enhance lighting and mood."
      },
      {
        "name": "InitParams Execute",
        "description": "Initializes and applies processing parameters to ensure consistent image processing across all captures."
      },
      {
        "name": "Sharp Depth 1",
        "description": "Adjusts the sharpness level applied to the first depth layer, enhancing clarity in foreground subjects."
      },
      {
        "name": "Sharp Depth 2",
        "description": "Fine-tunes sharpness for the second depth layer, improving detail in mid-ground regions."
      },
      {
        "name": "Big Sharp Radius",
        "description": "Sets the radius for applying strong sharpening effects, impacting larger areas of the image."
      },
      {
        "name": "Small Sharp Radius",
        "description": "Defines the radius for subtle sharpening effects, targeting smaller details and textures."
      },
      {
        "name": "Raw Sharpen A",
        "description": "Applies raw sharpening to enhance image details before other processing stages."
      },
      {
        "name": "Raw Sharpen B",
        "description": "An alternative raw sharpening parameter for additional control over pre-processed image sharpness."
      },
      {
        "name": "Zipper 2/Coef.Spatial 1",
        "description": "Mitigates zipper artifacts by adjusting spatial coefficients for sharpness and detail preservation."
      },
      {
        "name": "Zipper 3/Coef.Spatial 2",
        "description": "Further controls spatial coefficients to reduce zippering effects in high-frequency image areas."
      },
      {
        "name": "Sabre Sharp",
        "description": "Applies Sabre algorithm-based sharpening for balanced clarity and noise reduction."
      },
      {
        "name": "Sabre Sharp 2",
        "description": "A secondary Sabre sharpening parameter, offering enhanced control over image sharpness."
      },
      {
        "name": "Sabre Sharp 3",
        "description": "Further fine-tunes the Sabre sharpening process for specific image details or scenarios."
      },
      {
        "name": "Sabre Sharp Contrast",
        "description": "Boosts contrast during the Sabre sharpening process to improve edge definition."
      },
      {
        "name": "Sabre Texture Tuning",
        "description": "Adjusts the Sabre sharpening parameters for optimal texture reproduction and artifact minimization."
      },
      {
        "name": "Sharp Distrib 1",
        "description": "Defines the distribution of sharpness across the image, prioritizing certain regions for detail enhancement."
      },
      {
        "name": "Sharp Distrib 2",
        "description": "A secondary parameter to refine the distribution of sharpness for improved regional balance."
      },
      {
        "name": "Sharp Distrib 3",
        "description": "Further fine-tunes sharpness distribution across different areas of the image."
      },
      {
        "name": "Sharp Distrib Radius 1",
        "description": "Specifies the radius for sharpness distribution adjustments, impacting the area of effect."
      },
      {
        "name": "Sharp Distrib Radius 2",
        "description": "An additional radius parameter to control the scope of sharpness distribution effects."
      },
      {
        "name": "RAISR Small",
        "description": "Applies RAISR (Rapid and Accurate Image Super-Resolution) sharpening to small details."
      },
      {
        "name": "RAISR Medium",
        "description": "Targets medium-sized features for sharpening using RAISR technology."
      },
      {
        "name": "RAISR Large",
        "description": "Enhances sharpness in larger image areas using the RAISR method for detail preservation."
      },
      {
        "name": "Sharp Legibility",
        "description": "Improves sharpness in text and fine details, increasing overall clarity and legibility."
      },
      {
        "name": "Soft Sharp",
        "description": "Applies a gentle sharpening effect for a more natural and less harsh image appearance."
      },
      {
        "name": "Sharpness for the Noise Model (Merge 0 ONLY)",
        "description": "Adjusts sharpness specifically for noise modeling in Merge 0 processing, optimizing clarity and noise balance."
      },
      {
        "name": "NightSight Sharpness Delta Factor",
        "description": "Controls the sharpness intensity difference applied during NightSight mode to enhance nighttime image details."
      },
      {
        "name": "Sharp Gain",
        "description": "Amplifies the overall sharpening intensity applied across the image."
      },
      {
        "name": "Sharp Gain Macro",
        "description": "Increases sharpening specifically for macro photography, enhancing close-up details."
      },
      {
        "name": "Sharp Gain Micro",
        "description": "Fine-tunes sharpening for microscopic details, ensuring high fidelity in tiny textures."
      },
      {
        "name": "Sharpen MAX Detail (DENOISE)",
        "description": "Balances maximum detail sharpening with noise reduction for cleaner, sharper images."
      },
      {
        "name": "PolySharp Radius Small",
        "description": "Defines the radius for small-scale sharpening using PolySharp methods, focusing on fine details."
      },
      {
        "name": "PolySharp Radius Med",
        "description": "Adjusts the medium radius for sharpening via PolySharp, targeting mid-sized image details."
      },
      {
        "name": "PolySharp Radius Large",
        "description": "Controls the large radius for PolySharp sharpening, emphasizing broader image areas."
      },
      {
        "name": "Temporal Radius (Weiner M/Spatial RGB)",
        "description": "Defines the temporal radius for motion-adaptive sharpening in Weiner Merge and Spatial RGB modes."
      },
      {
        "name": "Temporal Radius 2",
        "description": "An additional parameter for refining the temporal sharpening radius in dynamic scenes."
      },
      {
        "name": "Smoothing Temporal (Weiner Merge)",
        "description": "Applies temporal smoothing during Weiner Merge processing to reduce motion artifacts."
      },
      {
        "name": "Smoothing Spatial (Weiner Merge)",
        "description": "Adjusts spatial smoothing in Weiner Merge for consistent detail and noise control."
      },
      {
        "name": "Smoothing NEW",
        "description": "Introduces advanced smoothing algorithms for improved image blending and noise reduction."
      },
      {
        "name": "Smoothness",
        "description": "General control for smoothing intensity across the entire image, balancing clarity and softness."
      },
      {
        "name": "Shot Noise Factor",
        "description": "Mitigates shot noise artifacts in low-light images, improving overall clarity."
      },
      {
        "name": "Noise Reduction Adjust (Opacity)",
        "description": "Controls the opacity level of noise reduction effects, determining their visibility in the final image."
      },
      {
        "name": "Luma Smoothing",
        "description": "Applies smoothing to the luminance channel, reducing noise while preserving detail."
      },
      {
        "name": "Chroma Smoothing",
        "description": "Targets the chrominance channel for noise reduction, enhancing color consistency."
      },
      {
        "name": "Denoise Smoothing",
        "description": "Balances denoising with smoothing to maintain clarity in low-light and noisy images."
      },
      {
        "name": "BayerNoiseModelHalide 1",
        "description": "Adjusts the first Bayer noise model parameter for fine control over image noise handling."
      },
      {
        "name": "BayerNoiseModelHalide 2",
        "description": "Refines the second Bayer noise model setting, impacting noise reduction and detail preservation."
      },
      {
        "name": "BayerNoiseModelHalide 3",
        "description": "A third parameter for tuning the Bayer noise model, optimizing clarity and noise control."
      },
      {
        "name": "Exposition Max HDR Ratio (HDRE|NS)",
        "description": "Sets the maximum exposure ratio for HDR processing in HDRE or Night Sight modes."
      },
      {
        "name": "Brightness (Earlier, Exposure 5 (Independent, Universal))",
        "description": "Controls overall image brightness independently of other exposure settings."
      },
      {
        "name": "Darken Light (ISO Limiting)",
        "description": "Adjusts brightness by limiting ISO, reducing overexposed areas."
      },
      {
        "name": "ISO/Exposure Tuning",
        "description": "Fine-tunes ISO and exposure settings for balanced lighting."
      },
      {
        "name": "Exposure Darker",
        "description": "Applies additional adjustments to darken the overall exposure."
      },
      {
        "name": "Exposure Darker2",
        "description": "Refines the darkening of exposure for specific scenarios."
      },
      {
        "name": "Average LDR TET",
        "description": "Calculates the average Total Exposure Time (TET) for Low Dynamic Range images."
      },
      {
        "name": "Merge Method",
        "description": "Specifies the method used for merging multiple frames into a final image."
      },
      {
        "name": "AeShotParams SetUxMode",
        "description": "Adjusts Auto Exposure settings to suit the selected UX mode."
      },
      {
        "name": "Sabre 1",
        "description": "Configures the first parameter of the Sabre processing algorithm."
      },
      {
        "name": "Sabre 2",
        "description": "Adjusts the second parameter in the Sabre image processing pipeline."
      },
      {
        "name": "Sabre 3",
        "description": "Fine-tunes the third aspect of Sabre processing for enhanced detail."
      },
      {
        "name": "Sabre Burst (Merge 1)",
        "description": "Optimizes Sabre settings for burst mode image merging."
      },
      {
        "name": "Spatial Gain MAP 1",
        "description": "Applies spatial gain adjustments to enhance specific image areas."
      },
      {
        "name": "Spatial Gain MAP 2",
        "description": "A second spatial gain map for additional regional image adjustments."
      },
      {
        "name": "Spatial Gain MAP 3",
        "description": "Further refines spatial gain mapping for balanced image enhancement."
      },
      {
        "name": "Fine Spatial MAX 1",
        "description": "Sets the maximum limit for fine spatial adjustments in the first channel."
      },
      {
        "name": "Fine Spatial MIN 1",
        "description": "Defines the minimum threshold for fine spatial adjustments in the first channel."
      },
      {
        "name": "Fine Spatial MAX 2",
        "description": "Configures the maximum limit for fine spatial adjustments in the second channel."
      },
      {
        "name": "Fine Spatial MIN 2",
        "description": "Adjusts the minimum threshold for fine spatial adjustments in the second channel."
      },
      {
        "name": "Smoothing the Sabre (Sabre Denoise)",
        "description": "Applies denoising while smoothing details during Sabre processing."
      },
      {
        "name": "Sabre Denoise Control (-31 is off) (Sabre Noise Artifacts)",
        "description": "Controls the intensity of denoising to minimize Sabre noise artifacts; -31 disables it."
      },
      {
        "name": "Better Color Wiener Sabre",
        "description": "Enhances color accuracy during Wiener and Sabre processing."
      },
      {
        "name": "Savannah Merge2 Chroma Denoise",
        "description": "Reduces chroma noise in Savannah Merge 2 processing for smoother color transitions."
      },
      {
        "name": "HDR Sabre Calc Val 1",
        "description": "Configures the first HDR calculation value for Sabre processing."
      },
      {
        "name": "HDR Sabre Calc Val 2",
        "description": "Adjusts the second HDR calculation value in Sabre processing."
      },
      {
        "name": "Sabre Brightness",
        "description": "Controls brightness adjustments specific to Sabre processing."
      },
      {
        "name": "Sabre Detail",
        "description": "Fine-tunes detail enhancement during Sabre processing."
      },
      {
        "name": "Motion Scale Factor",
        "description": "Determines the scaling factor for motion compensation in dynamic scenes."
      },
      {
        "name": "Rejected Denoise Multiplier",
        "description": "Adjusts the multiplier for denoising rejected frames to maintain consistency."
      },
      {
        "name": "Sabre MAX Scale Factor",
        "description": "Sets the maximum scale factor for Sabre processing, affecting overall adjustments."
      },
      {
        "name": "Sabre Noise Estimates",
        "description": "Calculates noise estimates to guide Sabre denoising algorithms."
      },
      {
        "name": "Noise Processing",
        "description": "Applies general noise reduction techniques across the image."
      },
      {
        "name": "Fix Noise Sabre",
        "description": "Addresses specific noise issues in Sabre-processed images."
      },
      {
        "name": "Fix Shasta Merge",
        "description": "Corrects merging artifacts during Shasta processing."
      },
      {
        "name": "Volume Processing 1",
        "description": "Controls the first stage of volume-based image processing adjustments."
      },
      {
        "name": "Volume Processing 2",
        "description": "Refines volume adjustments in the second stage of image processing."
      },
      {
        "name": "Suppress HOT Pixels (On/Off)",
        "description": "Enables or disables suppression of hot pixels in the final image."
      },
      {
        "name": "Hot Pixel Fix (Tuning Hot Pixels Factor)",
        "description": "Adjusts the tuning factor for correcting hot pixels in the image."
      },
      {
        "name": "Downsample Factor",
        "description": "Specifies the factor by which the image is downsampled during processing."
      },
      {
        "name": "Downsample by 2 before merge",
        "description": "Reduces image resolution by half before merging frames to improve processing efficiency."
      },
      {
        "name": "Discard Frames with Lowest Score",
        "description": "Excludes frames with the lowest quality scores from the final merge."
      },
      {
        "name": "Discard Frames with TET Mismatch",
        "description": "Removes frames with mismatched Total Exposure Time from merging to ensure consistency."
      },
      {
        "name": "Discard Dissimilar HDRE Frames for NS",
        "description": "Filters out frames that are too dissimilar during HDR or Night Sight processing."
      },
      {
        "name": "Discard Blurry Frames",
        "description": "Eliminates blurry frames from consideration during frame merging."
      },
      {
        "name": "Bracketed Sharpness Threshold",
        "description": "Sets the sharpness threshold for including bracketed frames in the merge process."
      },
      {
        "name": "BaseFrame Sharpness Threshold",
        "description": "Defines the minimum sharpness required for selecting a base frame."
      },
      {
        "name": "White Point",
        "description": "Adjusts the white point to calibrate image color balance and brightness."
      },
      {
        "name": "Black Point",
        "description": "Sets the black point to define shadow levels and image contrast."
      },
      {
        "name": "Dehazed_Expo",
        "description": "Applies dehazing adjustments to improve exposure and clarity in hazy images."
      },
      {
        "name": "Contrast 1",
        "description": "Controls the primary contrast adjustment for the image."
      },
      {
        "name": "Set Gamma Factor: 1/x (Contrast 2)",
        "description": "Adjusts the gamma curve to refine contrast levels."
      },
      {
        "name": "Dehazed Regulator 2",
        "description": "A secondary parameter to regulate dehazing intensity for consistent results."
      },
      {
        "name": "Contrast Black A",
        "description": "Fine-tunes contrast in darker areas of the image for improved shadow detail."
      },
      {
        "name": "Contrast Black B",
        "description": "Additional control over shadow contrast for enhanced tonal balance."
      },
      {
        "name": "Highlight_compensation (Lower is Brighter)",
        "description": "Adjusts highlight compensation to control brightness in bright areas."
      },
      {
        "name": "Shadows Compensation (Bigger is Brighter)",
        "description": "Controls brightness levels in shadowed areas of the image."
      },
      {
        "name": "Lighting",
        "description": "Applies general lighting adjustments to improve image exposure."
      },
      {
        "name": "High Lighting",
        "description": "Enhances lighting in bright areas to add clarity and detail."
      },
      {
        "name": "LDR Lighting",
        "description": "Adjusts lighting levels specifically for Low Dynamic Range images."
      },
      {
        "name": "HDR Range –",
        "description": "Reduces the dynamic range for HDR processing to control tonal balance."
      },
      {
        "name": "HDR Range +",
        "description": "Increases the dynamic range for HDR processing to capture more detail."
      },
      {
        "name": "HDR Overall Lightness",
        "description": "Controls the general lightness of HDR images for optimal brightness."
      },
      {
        "name": "HDR Effect Intensity",
        "description": "Adjusts the intensity of HDR effects to enhance image vibrancy."
      },
      {
        "name": "HDR Effect",
        "description": "Applies overall HDR effects to improve dynamic range and detail."
      },
      {
        "name": "HDR Ratio Limit Override",
        "description": "Allows manual override of HDR ratio limits for custom processing."
      },
      {
        "name": "HDR Ratio",
        "description": "Defines the ratio for HDR image blending and dynamic range adjustments."
      },
      {
        "name": "HDR Ratio 3",
        "description": "An additional parameter to refine HDR ratio for specific scenes."
      },
      {
        "name": "HDR Range Limit 1",
        "description": "Sets the first limit for HDR range adjustments."
      },
      {
        "name": "HDR Range Limit 2",
        "description": "Defines the second limit for HDR range adjustments."
      },
      {
        "name": "Brightness Intensity",
        "description": "Controls the intensity of brightness adjustments applied to the image."
      },
      {
        "name": "Preserve Highlights",
        "description": "Ensures that bright details are retained during image adjustments."
      },
      {
        "name": "ZSL Frames Count",
        "description": "Specifies the number of frames used in Zero Shutter Lag (ZSL) mode."
      },
      {
        "name": "HDRE Frames Count",
        "description": "Defines the number of frames processed for HDR Enhanced mode."
      },
      {
        "name": "NS Frames Count",
        "description": "Adjusts the number of frames used in Night Sight mode for noise reduction and detail enhancement."
      },
      {
        "name": "Samsung Metering Mode",
        "description": "Configures the metering mode specific to Samsung devices for exposure control."
      },
      {
        "name": "Initial Zoom",
        "description": "Sets the initial zoom level when capturing an image."
      },
      {
        "name": "Zoom Ratio",
        "description": "Defines the zoom ratio applied during image capture or processing."
      },
      {
        "name": "NS MAX Exposure Time (ms)",
        "description": "Sets the maximum exposure time for Night Sight mode to capture light in low-light conditions."
      },
      {
        "name": "Shasta MAX Exposure Time (ms)",
        "description": "Specifies the maximum exposure time for Shasta processing to optimize detail."
      },
      {
        "name": "HDR+ Model",
        "description": "Selects the HDR+ processing model for enhanced dynamic range and detail."
      },
      {
        "name": "Upscale Images",
        "description": "Enables or disables image upscaling to improve resolution."
      },
      {
        "name": "HDR Mode",
        "description": "Activates HDR mode for improved dynamic range and tonal balance."
      }
];
  
  

export default function SettingsMain() {
    const [camera, setCamera] = useState<any>({});
    
    return(
        <Box>
            <Container
            sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            pt: { xs: 14, sm: 20 },
            pb: { xs: 3, sm: 8 },
            borderBottom: '2px solid',
            borderColor: 'divider'
            }}
        >
            <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '100%' } }}>
            <Typography
                variant="h1"
                sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                alignSelf: 'center',
                textAlign: 'center',
                fontSize: 'clamp(3.5rem, 10vw, 4rem)',
                }}
            >
                Settings&nbsp;
            </Typography>
            <Typography
                textAlign="center"
                color="text.secondary"
                sx={{ alignSelf: 'center',  fontSize: '16px', width: { xs: '100%', sm: '80%' }}}
            >
                These settings provide detailed control over various aspects of image processing, including sharpness, noise, color, and brightness adjustments. Each parameter, from managing sharpness and noise to fine-tuning ISO and HDR balance, allows for customized image processing based on specific shooting conditions. The settings for color and light noise help improve image quality in challenging lighting situations, ensuring cleaner and sharper shots without sacrificing detail. Additionally, options for precise exposure and temporal delay adjustments offer the flexibility to achieve the best results in both bright and low-light environments.
            </Typography>
            </Stack>
            </Container>

            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: { xs: 7, sm: 10 },
                    pb: { xs: 3, sm: 8 },
                    borderBottom: '2px solid',
                    borderColor: 'divider'
                    }}
            >
              <Container sx={{alignItems: "center"}}>
      <AdsComponent 
          className='adsbygoogle'
          client={'ca-pub-5244484613216801'}
          slot={'8291271210"'}
          layoutKey="-6t+ed+2i-1n-4w"
          format='fluid'
          style={{display: "block", textAlign: "center"}}/>
    </Container>
                <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
                    <ParametersTable rows={main}/>
                </Stack>
                <Container sx={{alignItems: "center"}}>
      <AdsComponent 
          className='adsbygoogle'
          client={'ca-pub-5244484613216801'}
          slot={'8291271210"'}
          layoutKey="-6t+ed+2i-1n-4w"
          format='fluid'
          style={{display: "block", textAlign: "center"}}/>
    </Container>
            </Container>
        </Box>
    );
}