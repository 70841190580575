import * as React from 'react';
import Introduction from './Home/Introduction';
import CommonPage from './Common/CommonPage';
import Footer from './Footer/Footer';
import WhyDownloadPart from './Home/WhyDownloadPart';
import LogoCollection from './Home/LogoCollection';
import AboutGcam from './Home/AboutGcam';
import FAQ from './Home/FAQ';
import { AdsComponent } from './AdsComponent/AdsComponent';
import { Box, Container } from '@mui/material';


export default function LandingPage() {
  let main;
  main = (
    <>
    <Introduction/>
    <LogoCollection/>
    <Container sx={{alignItems: "center"}}>
      <AdsComponent 
          className='adsbygoogle'
          client={'ca-pub-5244484613216801'}
          slot={'8291271210"'}
          layoutKey="-6t+ed+2i-1n-4w"
          format='fluid'
          style={{display: "block", textAlign: "center"}}/>
    </Container>
    <AboutGcam/>
    <WhyDownloadPart/>
    <Container sx={{alignItems: "center"}}>
            <AdsComponent 
                className='adsbygoogle'
                client={'ca-pub-5244484613216801'}
                slot={'8659270011'}
                format='fluid'
                data-full-width-responsive="true"
                style={{display: "block", textAlign: "center"}}/>
    </Container>
    <FAQ/>
    <Footer/>
    </>
  );
  return (
   <CommonPage main={main}/>
  );
}